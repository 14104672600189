const FirebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINNG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// const FirebaseConfig = {
//   apiKey: "AIzaSyAKgqNCGf2ggP83yG8CmkIOaljZm2oJu3o",
//   authDomain: "kidgo-dev.firebaseapp.com",
//   projectId: "kidgo-dev",
//   storageBucket: "kidgo-dev.appspot.com",
//   messagingSenderId: "113265759026",
//   appId: "1:113265759026:web:3ac732338716f86357bd2b",
//   measurementId: "G-F7JWQC6DFW",
// };

// const FirebaseConfig_Prod = {
//   apiKey: "AIzaSyDK1xywIusz2L-ZjgDyf_7RYcRhAeaDuvo",
//   authDomain: "kidgo-live.firebaseapp.com",
//   projectId: "kidgo-live",
//   storageBucket: "kidgo-live.appspot.com",
//   messagingSenderId: "765723567789",
//   appId: "1:765723567789:web:95458f1f7de31f7eaf0824",
//   measurementId: "G-J1NPNFFSBZ",
// };

export default FirebaseConfig;
