import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { dataUserRole } from "api/data";
import "./views/app-views/css/kidgo.css";
import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";

const getUserRole = async () => {
  const data = await dataUserRole();
  return data[0]?.role ? data[0].role : "FIELDREP";
};

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

export const UserRoleContext = React.createContext("ADMIN");

function App() {
  const [userRole, setUserRole] = useState();
  const [themeConfig, setThemeConfig] = useState({});
  const [isColorLoaded, setIsColorLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [colorPrimary, setColorPrimary] = useState("#2D9E37");

  useEffect(async () => {
    setUserRole(await getUserRole());
  }, []);

  useEffect(() => {
    if (userRole) {
      if (userRole === "error") {
        //signOut();
        //return;
      }

      const colorAdmin = getComputedStyle(document.body).getPropertyValue(
        "--color-admin"
      );
      const colorDistrict = getComputedStyle(document.body).getPropertyValue(
        "--color-district"
      );
      const colorAdminRGB = getComputedStyle(document.body).getPropertyValue(
        "--color-admin-rgb"
      );
      const colorDistrictRGB = getComputedStyle(document.body).getPropertyValue(
        "--color-district-rgb"
      );

      if (userRole === "DISTRICTREP") {
        document.documentElement.style.setProperty(
          "--color-primary",
          colorDistrict
        );
        setColorPrimary(colorDistrict);

        document.documentElement.style.setProperty(
          "--color-primary-rgb",
          colorDistrictRGB
        );
        setColorPrimary(colorDistrictRGB);
      } else {
        document.documentElement.style.setProperty(
          "--color-primary",
          colorAdmin
        );
        setColorPrimary(colorAdmin);

        document.documentElement.style.setProperty(
          "--color-primary-rgb",
          colorAdminRGB
        );
        setColorPrimary(colorAdminRGB);
      }

      setIsColorLoaded(true);
    }
  }, [userRole]);

  useEffect(() => {
    if (isColorLoaded) {
      setThemeConfig({
        navCollapsed: true,
        sideNavTheme: SIDE_NAV_LIGHT,
        locale: "en",
        navType: NAV_TYPE_SIDE,
        topNavColor: colorPrimary,
        headerNavColor: colorPrimary,
        mobileNav: false,
        currentTheme: "light",
        direction: DIR_LTR,
      });
      localStorage.setItem("headerNavColor_v7", colorPrimary);
      localStorage.setItem("topNavColor_v7", colorPrimary);
      setIsLoaded(true);
    }
  }, [isColorLoaded]);

  return (
    <div className="App">
      {isLoaded && (
        <UserRoleContext.Provider value={userRole}>
          <Provider store={store}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={themeConfig.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </ThemeSwitcherProvider>
          </Provider>
        </UserRoleContext.Provider>
      )}
    </div>
  );
}

export default App;
