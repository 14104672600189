import React, { useState, useEffect, useContext } from "react";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Highlight,
  connectStateResults,
} from "react-instantsearch-dom";
import { Card, Typography, Grid } from "antd";
import algoliasearch from "algoliasearch";
import { API_FUNCTIONS, APP_NAME } from "configs/AppConfig";
import "../css/kidgo.css";
import "./styles.css";
import utils from "utils";
import { UserRoleContext } from "App";
import { NavLink } from "react-router-dom";
import Button from "devextreme-react/button";

let url = API_FUNCTIONS;

const appId = process.env.REACT_APP_ALGOLIA_API_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const client = algoliasearch(appId, apiKey);

const columnStyle = { width: 500, color: "black" };
let gridStyle = {};

function navigateToProfile(id) {
  window.location = "/app/profile/" + id;
}

function navigateToServiceRequest(id) {
  window.location = "/app/passenger/" + id;
}

function HitPeople(props) {
  let hitGridStyle = gridStyle;

  if (props.hit.objectID) {
    return (
      <Card.Grid
        style={hitGridStyle}
        onClick={() => navigateToProfile(`${props.hit.objectID}`)}
      >
        <div>
          <div>
            <Typography.Text ellipsis={true} style={columnStyle}>
              <Highlight attribute="firstName" hit={props.hit} />{" "}
              <Highlight attribute="lastName" hit={props.hit} />
            </Typography.Text>
          </div>
          <div style={{ fontSize: "smaller" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "50%", paddingRight: "10px" }}>
                    <div
                      style={{
                        width: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"MDD: "}
                      <Highlight attribute="mddNumber" hit={props.hit} />
                    </div>
                  </td>
                  <td style={{ width: "50%" }}>
                    <div
                      style={{
                        width: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Driver ID: "}
                      <Highlight attribute="driverID" hit={props.hit} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%", paddingRight: "10px" }}>
                    <div
                      style={{
                        width: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Rep: "}
                      <Highlight attribute="commissionToA" hit={props.hit} />
                    </div>
                  </td>
                  <div
                    style={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {"Vehicle: "}
                    <Highlight attribute="vehicleYear" hit={props.hit} />{" "}
                    <Highlight attribute="vehicleMake" hit={props.hit} />{" "}
                    <Highlight attribute="vehicleModel" hit={props.hit} />
                  </div>
                </tr>

                <tr>
                  <td
                    style={{ width: "100%", paddingRight: "10px" }}
                    colSpan="2"
                  >
                    <div
                      style={{
                        width: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Phone: "}
                      <Highlight attribute="phoneNumber" hit={props.hit} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "100%" }} colSpan="2">
                    <div
                      style={{
                        width: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"City: "}
                      <Highlight
                        attribute="addressPhysicalCity"
                        hit={props.hit}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "100%" }} colSpan="2">
                    <div
                      style={{
                        width: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Email: "}
                      <Typography.Text ellipsis={true}>
                        <Highlight attribute="email" hit={props.hit} />
                      </Typography.Text>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Card.Grid>
    );
  }
}

function HitPassengers(props) {
  let hitGridStyle = gridStyle;

  if (props.hit.objectID) {
    return (
      <Card.Grid
        style={hitGridStyle}
        onClick={() => navigateToServiceRequest(`${props.hit.objectID}`)}
      >
        <div>
          <div>
            <Typography.Text ellipsis={true} style={columnStyle}>
              <Highlight attribute="passengerFullName" hit={props.hit} />
            </Typography.Text>
          </div>

          <div style={{ fontSize: "smaller" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "5%", paddingRight: "2px" }}>
                    <div
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Primary Guardian: "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "75%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Highlight
                        attribute="primaryGuardianFullName"
                        hit={props.hit}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "5%", paddingRight: "2px" }}>
                    <div
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Primary Guardian Phone: "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "75%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Highlight
                        attribute="primaryGuardianPhoneFormatted"
                        hit={props.hit}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "5%", paddingRight: "2px" }}>
                    <div
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"School: "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "75%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Highlight attribute="school" hit={props.hit} />
                    </div>
                  </td>
                </tr>
                {/* <tr>
                  <td style={{ width: "5%", paddingRight: "2px" }}>
                    <div
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {"Service Request: "}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "75%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <NavLink
                        to={`/app/passenger/${props.hit.objectID}`}
                        activeClassName="dx-link"
                      >
                        View
                      </NavLink>

                      <Button
                        text="View"
                        onClick={() =>
                          navigateToServiceRequest(props.hit.objectID)
                        }
                      />
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </Card.Grid>
    );
  }
}

const Search = (props) => {
  const [userRole, setUserRole] = useState(useContext(UserRoleContext));
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [indexName, setIndexName] = useState("people");
  const [hitPeople, setHitPeople] = useState(true);

  useEffect(() => {
    if (userRole) {
      setIsUserLoaded(true);
    }
  }, [userRole]);

  useEffect(() => {
    if (isUserLoaded) {
      if (userRole === "DISTRICTREP") {
        setIndexName("passengers");
        setHitPeople(false);
      } else {
        setIndexName("people");
        setHitPeople(true);
      }
    }
  }, [isUserLoaded]);

  const [displaySearchResults, setDisplaySearchResults] = useState(true);
  //const [searchActive, setSearchActive] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  //console.log("isMobile search", isMobile);

  useEffect(() => {
    gridStyle = {
      width: !isMobile ? "40%" : "100%",
      textAlign: "left",
      wordWrap: "break-word",
      cursor: "pointer",
    };
  }, [isMobile]);

  function setSearchActive(searchActive) {
    setDisplaySearchResults(searchActive);
  }

  const { hideResults } = props;
  // if (hideResults) {
  //   setDisplaySearchResults(false);
  // }

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) =>
      searchState && searchState.query && !hideResults ? (
        children
      ) : (
        <span style={{ display: "none" }} />
      )
  );

  return (
    <>
      <InstantSearch
        appId={appId}
        apiKey={apiKey}
        indexName={indexName}
        searchClient={client}
      >
        <div style={{ width: "180px" }}>
          <SearchBox
            translations={{ placeholder: "Search..." }}
            reset={
              <img
                src="/img/others/reset.png"
                height="20px"
                width="20px"
                alt="Reset search"
                style={{ cursor: "pointer" }}
              />
            }
          />
        </div>
        <div
          id="scrollableDiv"
          style={{
            overflowY: "auto",
            overflowYdisplay: "flex",
            height: "80vh",
            position: "absolute",
            backgroundColor: "white",
            top: "60px",
          }}
        >
          <Results>
            {!isMobile && hitPeople && (
              <Card style={{ width: "900px" }}>
                <Hits hitComponent={HitPeople} isMobile={isMobile} />
              </Card>
            )}

            {isMobile && hitPeople && (
              <Card style={{ width: "350px" }}>
                <Hits hitComponent={HitPeople} />
              </Card>
            )}

            {!isMobile && !hitPeople && (
              <Card style={{ width: "900px" }}>
                <Hits hitComponent={HitPassengers} isMobile={isMobile} />
              </Card>
            )}

            {isMobile && !hitPeople && (
              <Card style={{ width: "350px" }}>
                <Hits hitComponent={HitPassengers} />
              </Card>
            )}
          </Results>
        </div>
      </InstantSearch>
    </>
  );
};

export default Search;
