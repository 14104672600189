import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

function configureStore(preloadedState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

const store = configureStore();

export default store;

// import { createStore, applyMiddleware, compose } from "redux";
// import { persistStore, persistReducer } from "redux-persist";

// import reducers from "../reducers";
// import createSagaMiddleware from "redux-saga";
// import rootSaga from "../sagas/index";

// import storage from "redux-persist/lib/storage";
// import rootReducer from "../reducers";

// const sagaMiddleware = createSagaMiddleware();

// const middlewares = [sagaMiddleware];

// function configureStore(preloadedState) {
//   const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   const store = createStore(
//     reducers,
//     preloadedState,
//     composeEnhancers(applyMiddleware(...middlewares))
//   );

//   sagaMiddleware.run(rootSaga);

//   if (module.hot) {
//     module.hot.accept("../reducers/index", () => {
//       const nextRootReducer = require("../reducers/index");
//       store.replaceReducer(nextRootReducer);
//     });
//   }

//   return store;
// }

// //const store = configureStore();

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// //const persistor = persistStore(store);

// export const store = createStore(persistedReducer, configureStore());
// export const persistor = persistStore(store);

// // export default store;
// // export { persistor };

// // export default () => {
// //   let store = createStore(persistedReducer);
// //   let persistor = persistStore(store);
// //   return { store, persistor };
// // };
