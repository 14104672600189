import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import config from "devextreme/core/config";

config({
  forceIsoDateParsing: false,
});

var appName = process.env.REACT_APP_NAME;
export const APP_NAME = appName;
export const APP_PLATFORM = process.env.REACT_APP_PLATFORM;
export const APP_TYPE = process.env.REACT_APP_TYPE;

export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
// export const API_FUNCTIONS_LOCAL =
//   "http://localhost:5001/kidgo-dev/us-central1";
// export const API_FUNCTIONS_DEV =
//   "https://us-central1-kidgo-dev.cloudfunctions.net";

export const API_FUNCTIONS = process.env.REACT_APP_API_FUNCTIONS;
export const API_FUNCTIONS_KML = process.env.REACT_APP_API_FUNCTIONS_KML;

export const THEME_CONFIG = {
  navCollapsed: !localStorage.getItem("navCollapsed_v7")
    ? true
    : localStorage.getItem("navCollapsed_v7"),

  sideNavTheme: !localStorage.getItem("sideNavTheme_v7")
    ? SIDE_NAV_LIGHT
    : localStorage.getItem("sideNavTheme_v7"),

  locale: !localStorage.getItem("locale_v7")
    ? "en"
    : localStorage.getItem("locale_v7"),

  navType: !localStorage.getItem("navType_v7")
    ? NAV_TYPE_SIDE
    : localStorage.getItem("navType_v7"),

  topNavColor: !localStorage.getItem("topNavColor_v7")
    ? "#2D9E37"
    : localStorage.getItem("topNavColor_v7"),

  headerNavColor: !localStorage.getItem("headerNavColor_v7")
    ? "#2d9e37"
    : localStorage.getItem("headerNavColor_v7"),

  mobileNav: !localStorage.getItem("mobileNav_v7")
    ? false
    : localStorage.getItem("mobileNav_v7"),

  currentTheme: !localStorage.getItem("currentTheme_v7")
    ? "light"
    : localStorage.getItem("currentTheme_v7"),

  direction: !localStorage.getItem("direction_v7")
    ? DIR_LTR
    : localStorage.getItem("direction_v7"),
};
