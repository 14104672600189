import {
  HomeOutlined,
  DashboardOutlined,
  TeamOutlined,
  FileDoneOutlined,
  DollarOutlined,
  CompassOutlined,
  EnvironmentOutlined,
  CarOutlined,
  MailOutlined,
  MessageOutlined,
  CommentOutlined,
  UserSwitchOutlined,
  ControlOutlined,
  CheckCircleOutlined,
  UsergroupAddOutlined,
  BarsOutlined,
  BarChartOutlined,
  FullscreenOutlined,
  PartitionOutlined,
  BankOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const menuAdmin = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard.menu",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-people",
        path: `${APP_PREFIX_PATH}/people`,
        title: "sidenav.apps.people",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-routes",
        path: `${APP_PREFIX_PATH}/routes`,
        title: "sidenav.dashboard.routes",
        icon: CompassOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-routes-list",
            path: `${APP_PREFIX_PATH}/routes`,
            title: "sidenav.dashboard.routes",
            icon: CarOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-routes-map",
            path: `${APP_PREFIX_PATH}/route-analysis`,
            title: "sidenav.dashboard.routes.analysis",
            icon: EnvironmentOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-driver-switches",
            path: `${APP_PREFIX_PATH}/driver-switches`,
            title: "sidenav.dashboard.routes.switches",
            icon: SwapOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-communication",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.dashboard.communications",
        icon: CommentOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-comm1",
            path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
            title: "sidenav.dashboard.communications.email",
            icon: MailOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-comm2",
            path: `${APP_PREFIX_PATH}/apps/chat`,
            title: "sidenav.dashboard.communications.chat",
            icon: MessageOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-reports",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.dashboard.reports",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-reports-documents",
            path: `${APP_PREFIX_PATH}/reports/documents`,
            title: "sidenav.dashboard.reports.documents",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-reports4",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.dashboard.finance",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-reports43",
            path: `${APP_PREFIX_PATH}/pay`,
            title: "sidenav.dashboard.finance.pay",
            icon: UserSwitchOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-config",
        path: `${APP_PREFIX_PATH}/ob-requirements`,
        title: "sidenav.apps.config",
        icon: ControlOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-people-admin",
            path: `${APP_PREFIX_PATH}/people-admin`,
            title: "sidenav.apps.people.admin",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-routes-admin",
            path: `${APP_PREFIX_PATH}/routes-admin`,
            title: "sidenav.dashboard.routes.admin",
            icon: CarOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-config-dispatch",
            path: `${APP_PREFIX_PATH}/dispatch`,
            title: "sidenav.apps.config.dispatch",
            icon: FullscreenOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-config-districts",
            path: `${APP_PREFIX_PATH}/districts`,
            title: "sidenav.apps.config.districts",
            icon: PartitionOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-config1",
            path: `${APP_PREFIX_PATH}/emailTemplates`,
            title: "sidenav.apps.config.emailTemplates",
            icon: MailOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-config2",
            path: `${APP_PREFIX_PATH}/lookups`,
            title: "sidenav.apps.config.lookups",
            icon: BarsOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-config3",
            path: `${APP_PREFIX_PATH}/ob-requirements`,
            title: "sidenav.apps.config.obRequirements",
            icon: CheckCircleOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-config4",
            path: `${APP_PREFIX_PATH}/users`,
            title: "sidenav.apps.config.users",
            icon: UsergroupAddOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const menuFieldRep = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-people",
        path: `${APP_PREFIX_PATH}/people`,
        title: "sidenav.apps.people",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-routes",
        path: `${APP_PREFIX_PATH}/routes`,
        title: "sidenav.dashboard.routes",
        icon: CompassOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "apps-communication",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.dashboard.communications",
        icon: CommentOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-comm1",
            path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
            title: "sidenav.dashboard.communications.email",
            icon: MailOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-comm2",
            path: `${APP_PREFIX_PATH}/apps/chat`,
            title: "sidenav.dashboard.communications.chat",
            icon: MessageOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-reports",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.dashboard.reports",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-reports-documents",
            path: `${APP_PREFIX_PATH}/reports/documents`,
            title: "sidenav.dashboard.reports.documents",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "apps-reports",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.dashboard.reports",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const menuDistrictRep = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "apps-service-requests",
        path: `${APP_PREFIX_PATH}/service-requests/view`,
        title: "sidenav.dashboard.service",
        icon: CarOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "apps-routes",
        path: `${APP_PREFIX_PATH}/routes`,
        title: "sidenav.dashboard.routes",
        icon: CompassOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "apps-schools",
        path: `${APP_PREFIX_PATH}/schools/view`,
        title: "sidenav.dashboard.schools",
        icon: BankOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "apps-reports",
        path: `${APP_PREFIX_PATH}/district-reports`,
        title: "sidenav.dashboard.reports",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

function NavigationConfig(userRole) {
  //const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
  // const isAdmin = localStorage.getItem("isAdmin");
  // const navTree =
  //   isAdmin === "true" ? [...menuAdmin] : [...menuFieldRep];

  if (userRole === "DISTRICTREP") {
    return [...menuDistrictRep];
  } else {
    const isAdmin = userRole == "ADMIN" ? true : false;
    const navTree = isAdmin ? [...menuAdmin] : [...menuFieldRep];
    return [...navTree];
  }
}

export default NavigationConfig;
