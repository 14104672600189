import { createStore } from "devextreme-aspnet-data-nojquery";
import { API_FUNCTIONS } from "configs/AppConfig";
const url = API_FUNCTIONS;
const uid = localStorage.getItem("auth_token");

// data lists
//export const dataRoles = ["ADMIN", "DISPATCH OPERATOR", "FIELD REP"];

export const dataLookupTypes = [
  "County",
  "Equipment",
  "Route Status",
  "Status",
  "WC Van Type",
  "Driver Switch Status",
];

// data stores - Lookups
export const dataReps = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=Rep&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataEquipment = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=Equipment&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataDispatchAreas = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=Dispatch%20Area&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataDistricts = createStore({
  key: "id",
  loadUrl: `${url}/districts/sql?uid=${uid}`,
  updateUrl: `${url}/districts/sql?uid=${uid}`,
  insertUrl: `${url}/districts/sql?uid=${uid}`,
  deleteUrl: `${url}/districts/sql?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataSchools = createStore({
  key: "id",
  loadUrl: `${url}/schools/sql?uid=${uid}`,
  updateUrl: `${url}/schools/sql?uid=${uid}`,
  insertUrl: `${url}/schools/sql?uid=${uid}`,
  deleteUrl: `${url}/schools/sql?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataPassengers = createStore({
  key: "id",
  loadUrl: `${url}/passengers/sql?uid=${uid}`,
  updateUrl: `${url}/passengers/sql?uid=${uid}`,
  insertUrl: `${url}/passengers/sql?uid=${uid}`,
  deleteUrl: `${url}/passengers/sql?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataRouteStatus = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=Route%20Status&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataDriverSwitchStatus = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=Driver%20Switch%20Status&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataCounties = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=County&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataStates = createStore({
  key: "value",
  loadUrl: `${url}/lookups/sql?type=State&uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataLookupsAdmin = createStore({
  key: "id",
  loadUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  updateUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  insertUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  deleteUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataLookupsDispatchAreas = createStore({
  key: "id",
  loadUrl: `${url}/lookups/sql/dispatch-areas?uid=${uid}`,
  updateUrl: `${url}/lookups/sql/dispatch-areas?uid=${uid}`,
  insertUrl: `${url}/lookups/sql/dispatch-areas?uid=${uid}`,
  deleteUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataLookupsDispatchOperators = createStore({
  key: "id",
  loadUrl: `${url}/lookups/sql/dispatch-operators?uid=${uid}`,
  updateUrl: `${url}/lookups/sql/dispatch-operators?uid=${uid}`,
  insertUrl: `${url}/lookups/sql/dispatch-operators?uid=${uid}`,
  deleteUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataLookupsDispatchReps = createStore({
  key: "id",
  loadUrl: `${url}/lookups/sql/dispatch-reps?uid=${uid}`,
  updateUrl: `${url}/lookups/sql/dispatch-reps?uid=${uid}`,
  insertUrl: `${url}/lookups/sql/dispatch-reps?uid=${uid}`,
  deleteUrl: `${url}/lookups/sql/admin?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataLookupsDispatchRepsNamesOnly = createStore({
  loadUrl: `${url}/lookups/sql/dispatch-reps-names-only?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

// data stores
export const dataDriverNames = createStore({
  key: "id",
  loadUrl: `${url}/people/sql/driver-names?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataUserRoles = createStore({
  key: "id",
  loadUrl: `${url}/user/sql/roles?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataDrivers = createStore({
  key: "id",
  loadUrl: `${url}/people/sql/driver-names?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
  sort: "fullName",
});

export const dataDriversByRep = async (rep) => {
  return fetch(`${url}/people/sql/drivers/rep?uid=${uid}&rep=${rep}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataMonitors = createStore({
  key: "id",
  loadUrl: `${url}/people/sql/monitor-names?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
  sort: "fullName",
});

export const dataDriverPins = async () => {
  return fetch(`${url}/people/sql/driver/pins?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataMonitorPins = async () => {
  return fetch(`${url}/people/sql/monitor/pins?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataUsers = createStore({
  key: "id",
  loadUrl: `${url}/user/sql?uid=${uid}`,
  updateUrl: `${url}/user/sql?uid=${uid}`,
  insertUrl: `${url}/user/sql?uid=${uid}`,
  deleteUrl: `${url}/user/sql?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataUserDisplayNames = createStore({
  key: "id",
  loadUrl: `${url}/user/display-names/sql?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataRoutes = (props) => {
  let status = "ACTIVE";
  let excludeId = null;

  if (props) {
    status = props.status ? props.status : "ACTIVE";
    excludeId = props.excludeId ? props.excludeId : null;
  }

  return createStore({
    key: "id",
    loadUrl: `${url}/routes/sql?uid=${uid}&status=${status}&exclude=${excludeId}`,
    loadMode: "raw",
  });
};

export const dataRouteOffers = (props) => {
  let status = "OFFER";
  let excludeId = null;

  if (props) {
    status = props.status ? props.status : "OFFER";
    excludeId = props.excludeId ? props.excludeId : null;
  }

  return createStore({
    key: "id",
    loadUrl: `${url}/routes/sql/offers?uid=${uid}&status=${status}`,
    loadMode: "raw",
  });
};

export const dataRoutesByGroup = (group, excludeId) =>
  createStore({
    key: "id",
    loadUrl: `${url}/routes/sql?uid=${uid}&status=ACTIVE&group=${group}&exclude=${excludeId}`,
    loadMode: "raw",
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: false };
    },
  });

export const dataDriverSwitchesByGroup = (group, excludeId) =>
  createStore({
    key: "id",
    loadUrl: `${url}/driverSwitches/sql?uid=${uid}&status=ACTIVE&group=${group}&exclude=${excludeId}`,
    loadMode: "raw",
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: false };
    },
  });

export const dataEmailTemplates = createStore({
  key: "id",
  loadUrl: `${url}/emailTemplates/sql/admin?uid=${uid}`,
  updateUrl: `${url}/emailTemplates/sql/admin?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

// dashboard items
export const dataExpiredExpiring = createStore({
  key: "id",
  loadUrl: `${url}/dashboard/sql/expired-expiring-ob-requirements?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataStatus = (props) =>
  createStore({
    key: "id",
    loadUrl: `${url}/dashboard/sql/status?status=${props.status}&uid=${uid}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: false };
    },
    loadMode: "raw",
  });

// data fetch
export const dataLookups = async (type, alt, filter) => {
  return fetch(
    `${url}/lookups/sql?uid=${uid}&type=${type}&alt=${alt}&filter=${filter}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => response.json());
};

export const dataRouteOffer = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/offer?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteOfferDispatchAreas = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/dispatch-areas?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteOfferDispatchAreaReps = async (routeId) => {
  return fetch(
    `${url}/routes/sql/${routeId}/offer/dispatch-area-reps?uid=${uid}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => response.json());
};

export const dataRouteOfferRep = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/offer?uid=${uid}&repUid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteOfferReps = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/reps?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteAccessShared = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/access?type=shared&uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteAccessDriver = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/access?type=driver&uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataRouteOfferUser = async (routeId) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/user?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const dataEmailTemplateVariables = async (templateId) => {
  return fetch(
    `${url}/emailTemplates/sql/admin/${templateId}/variables?uid=${uid}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    var data = await response.json();
    return data;
  });
};

export const dataRouteOfferShareWithUsers = async (routeId, users) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/share/reps?uid=${uid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(users),
  }).then(async (response) => {});
};

export const dataRouteOfferShareWithDrivers = async (routeId, drivers) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/share/drivers?uid=${uid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(drivers),
  }).then(async (response) => {});
};

export const dataRouteOfferAccept = async (routeId, driverId) => {
  return fetch(`${url}/routes/sql/${routeId}/offer/accept?uid=${uid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driverId),
  }).then(async (response) => {
    return await response.json();
  });
};

export const dataPayViews = createStore({
  key: "id",
  loadUrl: `${url}/pay/sql/payViews?uid=${uid}`,
  updateUrl: `${url}/pay/sql/payViews?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});

export const dataPayView = async (id) => {
  return fetch(`${url}/pay/sql/payView/${id}?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (response) => {
    var data = await response.json();
    return data;
  });
};

export const dataPayViewUpdate = async (id, payView) => {
  return fetch(`${url}/pay/sql/payView/${id}?uid=${uid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payView),
  }).then(async (response) => {
    return await response.json();
  });
};

export const dataUserRole = async () => {
  return fetch(`${url}/user/sql/role/${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      let data = await response.json();
      return data;
    })
    .catch((error) => {
      console.log("user error", error);
      let data = [{ id: 0, role: "error" }];
      return data;
    });
};

export const dataPassenger = async (id) => {
  return fetch(`${url}/passengers/sql/${id}?uid=${uid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (response) => {
    var data = await response.json();
    return data;
  });
};

export const dataPassengerUpdate = async (id, passenger) => {
  return fetch(`${url}/passengers/sql/${id}?uid=${uid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(passenger),
  }).then(async (response) => {
    console.log(response);
    return true;
  });
};

export const dataPassengerAdd = async () => {
  return fetch(`${url}/passengers/sql?uid=${uid}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ uid: uid }),
  }).then(async (response) => {
    return await response.json();
  });
};

// dataServiceRequests = async (filter) => {
//   return fetch(`${url}/passengers/sql?filter=${filter}&uid=${uid}`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   }).then(async (response) => {
//     return await response.json();
//   });
// };

export const dataServiceRequests = (props) =>
  createStore({
    key: "id",
    loadUrl: `${url}/passengers/sql?districtId=${props.districtId}&status=${props.status}&uid=${uid}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: false };
    },
    loadMode: "raw",
  });

export const dataDistrictDashboardCounts = async (districtId) => {
  return fetch(
    `${url}/dashboard/sql/district/counts?uid=${uid}&districtId=${districtId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    return await response.json();
  });
};

export const dataPayImport = async (payId, startDate, endDate, json) => {
  return fetch(`${url}/pay/sql/payImport?uid=${uid}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payId: payId,
      startDate: startDate,
      endDate: endDate,
      json: json,
    }),
  }).then(async (response) => {
    console.log(response);
    return true;
  });
};

export const dataRouteImport = async (json, fileName) => {
  return fetch(
    `${url}/routes/sql/routeImport?uid=${uid}&fileName=${fileName}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        json: json,
      }),
    }
  )
    .then(async (response) => {
      return response.json();
    })
    .then((jsonResponse) => {
      let routeId = JSON.parse(jsonResponse).routeId;
      console.log(routeId);
      return routeId;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const dataToDos = createStore({
  key: "id",
  loadUrl: `${url}/toDos/sql?uid=${uid}`,
  updateUrl: `${url}/toDos/sql?uid=${uid}`,
  insertUrl: `${url}/toDos/sql?uid=${uid}`,
  deleteUrl: `${url}/toDos/sql?uid=${uid}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
  },
  loadMode: "raw",
});
