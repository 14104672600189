import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";
import firebaseConfig from "configs/FirebaseConfig";
import axios from "axios";
import { API_FUNCTIONS_LOCAL, API_FUNCTIONS } from "configs/AppConfig";
let url = API_FUNCTIONS;
//if (window.location.hostname === //"localhost") {
////url = API_FUNCTIONS_LOCAL;
//}
const uid = localStorage.getItem("auth_token");

export const app = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === "localhost") {
  app.functions().useEmulator("localhost", 5001);
  //app.storage().useEmulator("localhost", 9199);
  app.auth().useEmulator("http://localhost:9099");
  app.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  });
}

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const storage = new firebase.storage();

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();

  // navigator.serviceWorker
  //   .register("firebase-messaging-sw.js")
  //   .then((registration) => {
  //     messaging.useServiceWorker(registration);
  //     // request notification permission and get token
  //     console.log("Registration successful, scope is:", registration.scope);
  //     //TODO: ask For Permission To Receive Notifications
  //   })
  //   .catch(function (err) {
  //     console.log("Service worker registration failed, error:", err);
  //   });
}

export const requestForToken = async () => {
  console.log("requestForToken");
  return messaging
    .getToken({
      vapidKey: `BDWdD42xJCKtop3KIu4_bkD9QB92Oh38hoqwtCX27sUIKqDQFA2aiQaV2CJx7pYxGaQukJDv2g_Qb96YhdeY5d8`,
    })
    .then(async (currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
        var saveToken = await axios(
          `${url}/chatToken/${currentToken}?uid=${uid}`
        );
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export {
  db,
  auth,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
  storage,
  messaging,
};
