import React, { lazy, Suspense, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { UserRoleContext } from "App";

export const AppViews = () => {
  //const isAdmin = localStorage.getItem("isAdmin");
  const userRole = useContext(UserRoleContext);
  const isAdmin = userRole == "ADMIN" ? true : false;

  const Routes = () => {
    return (
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/people`}
          component={lazy(() => import(`./peopleLite`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/routes`}
          component={lazy(() => import(`./routes`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/driver-switches`}
          component={lazy(() => import(`./driver-switches`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/route/:id`}
          component={lazy(() => import(`./routes/route`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/route-analysis`}
          component={lazy(() => import(`./route-analysis`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profile/:id`}
          component={lazy(() => import(`./profile`))}
        />
        {/* 
      <Route
        path={`${APP_PREFIX_PATH}/reports`}
        component={lazy(() => import(`./reports`))}
      /> */}

        <Route
          path={`${APP_PREFIX_PATH}/reports/documents`}
          component={lazy(() => import(`./reports/documents`))}
        />

        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/people-admin` : "./"}
          component={lazy(() => import(`./people`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/pay` : "./"}
          component={lazy(() => import(`./pay`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/routes-admin` : "./"}
          component={lazy(() => import(`./routes-admin`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/search` : "./"}
          component={lazy(() => import(`./search`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/ob-requirements` : "./"}
          component={lazy(() => import(`./ob-requirements`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/users` : "./"}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/emailtemplates` : "./"}
          component={lazy(() => import(`./emailTemplates`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/lookups` : "./"}
          component={lazy(() => import(`./lookups`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/dispatch` : "./"}
          component={lazy(() => import(`./dispatch`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/districts` : "./"}
          component={lazy(() => import(`./districts`))}
        />
        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/sandbox` : "./"}
          component={lazy(() => import(`./sandbox`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/files`}
          component={lazy(() => import(`./files/FileUpload`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/service-requests/:status`}
          component={lazy(() => import(`./service-requests`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/schools/:status`}
          component={lazy(() => import(`./schools`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/passenger/:id`}
          component={lazy(() => import(`./passenger`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/map-test/:id`}
          component={lazy(() => import(`./passenger/map-test`))}
        />

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    );
  };

  const RoutesDistrict = () => {
    return (
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/routes`}
          component={lazy(() => import(`./routes`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/route/:id`}
          component={lazy(() => import(`./routes/route`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/reports/documents`}
          component={lazy(() => import(`./reports/documents`))}
        />

        <Route
          path={isAdmin ? `${APP_PREFIX_PATH}/search` : "./"}
          component={lazy(() => import(`./search`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/district-reports`}
          component={lazy(() => import(`./district-reports`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/service-requests/:status`}
          component={lazy(() => import(`./service-requests`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/schools/:status`}
          component={lazy(() => import(`./schools`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/passenger/:id`}
          component={lazy(() => import(`./passenger`))}
        />

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    );
  };

  return (
    (userRole == "DISTRICTREP" && (
      <Suspense fallback={<Loading cover="content" />}>
        <RoutesDistrict />
      </Suspense>
    )) || (
      <Suspense fallback={<Loading cover="content" />}>
        <Routes />
      </Suspense>
    )
  );
};

export default React.memo(AppViews);
