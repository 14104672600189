import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";

import NavProfile from "./NavProfile";
import NavSearch from "./NavSearch";
import NavNotification from "./NavNotification";
import SearchInput from "./NavSearch/SearchInput.js";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { APP_TYPE } from "configs/AppConfig";
import { dataUserRole } from "api/data";

const appType = APP_TYPE;

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
  } = props;
  const [searchActive, setSearchActive] = useState(false);
  const [hideSearchResults, setHideSearchResults] = useState(false);

  const [userRole, setUserRole] = useState();
  const [themeConfig, setThemeConfig] = useState({});
  const [isColorLoaded, setIsColorLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [colorPrimary, setColorPrimary] = useState(headerNavColor);

  useEffect(async () => {
    let isMounted = true;
    const userRole = await getUserRole();
    return () => {
      isMounted = false;
    };

    async function getUserRole() {
      if (isMounted) {
        const data = await dataUserRole();
        setUserRole(data[0]?.role ? data[0].role : "FIELDREP");
      }
    }
  }, []);

  useEffect(() => {
    if (userRole) {
      const colorAdmin = getComputedStyle(document.body).getPropertyValue(
        "--color-admin"
      );
      const colorDistrict = getComputedStyle(document.body).getPropertyValue(
        "--color-district"
      );
      if (userRole === "DISTRICTREP") {
        document.documentElement.style.setProperty(
          "--color-primary",
          colorDistrict
        );
        setColorPrimary(colorDistrict);
      } else {
        document.documentElement.style.setProperty(
          "--color-primary",
          colorAdmin
        );
        setColorPrimary(colorAdmin);
      }

      setIsColorLoaded(true);
    }
  }, [userRole]);

  useEffect(() => {
    if (isColorLoaded) {
      // localStorage.setItem("headerNavColor_v7", colorPrimary);
      // localStorage.setItem("topNavColor_v7", colorPrimary);
    }
  }, [isColorLoaded]);

  const onSearchActive = () => {
    setSearchActive(true);
    setHideSearchResults(false);
  };

  const onSearchClose = () => {
    setSearchActive(false);
    setHideSearchResults(true);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!colorPrimary) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(colorPrimary);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: colorPrimary }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {isMobile ? (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onSearchActive();
                  }}
                >
                  <SearchOutlined />
                </li>
              ) : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  style={{ cursor: "auto" }}
                >
                  <SearchInput mode={mode} isMobile={isMobile} />
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            <div
              style={{
                alignVertical: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: "larger",
                letterSpacing: "3px",
              }}
            >
              {appType}
            </div>
            <NavNotification />
            <NavProfile />
            {/* <NavPanel direction={direction} /> */}
          </div>
          <NavSearch
            active={searchActive}
            close={onSearchClose}
            hideResults={hideSearchResults}
          />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
