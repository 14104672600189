import React from "react";
import { APP_NAME, APP_NAME_PROD } from "configs/AppConfig";
const appName = APP_NAME;

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${appName}`}</span> All rights
        reserved.
      </span>
    </footer>
  );
}
